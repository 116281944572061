<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewTaskForm"
        ref="addNewTaskForm"
        class="w-100 pt-6"
        v-model="isFormValid"
        @submit.prevent="submitNewTaskForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Zlecający pracownik"
              readonly
              :value="fullName"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="users"
              label="Przypisany pracownik"
              placeholder="Przypisz pracownika"
              v-model="task.assignedUserId"
              :item-text="item => `${item.firstName} ${item.lastName}`"
              item-value="id"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Temat"
              v-model="task.topic"
              :rules="[rules.required]"
              placeholder="Wpisz temat"
            />
          </v-col>

          <v-col cols="12">
            <v-menu
              v-model="showDatePicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  :rules="showDatePicker ? [] : [rules.required]"
                  label="Data realizacji"
                  placeholder="Wybierz datę realizacji"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="task.dueDate"
                v-bind="attributes.datePicker"
                :min="minDate"
                @input="showDatePicker = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="3"
              auto-grow
              label="Opis zadania"
              v-model="task.description"
              :rules="[rules.required]"
              placeholder="Wpisz opis zadania"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewTaskForm"
      >
        Dodaj zadanie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat } from '../../utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      attributes,
      isFormValid: false,
      showDatePicker: false,
      task: {
        dueDate: new Date().toISOString().substr(0, 10),
        topic: null,
        description: null,
        assignedUserId: null
      }
    }
  },
  computed: {
    ...mapState({
      userId: state => state.auth.user.id,
      users: state => state.users.items,
      isProcessing: state => state.tasks.isProcessing
    }),
    ...mapGetters({
      fullName: 'auth/getFullName'
    }),
    minDate () {
      return DateTime.utc().toISODate()
    },
    formattedDate () {
      if (this.task.dueDate) {
        return dateStringFormat(this.task.dueDate)
      }
      return ''
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    ...mapActions({
      addNewTask: 'tasks/addNewItem',
      getTasks: 'tasks/getItems',
      getUsers: 'users/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewTaskForm () {
      const params = { ...this.task, orderingUserId: this.userId }
      this.$refs.addNewTaskForm.validate()
      if (this.isFormValid) {
        this.addNewTask({ params })
          .then(() => {
            this.getTasks()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
